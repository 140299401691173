@use '../../assets/scss/index' as *; //TODO this is bringing in too much

.scs-image .scs-image-caption {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.54);
	padding: 0.5em;
	color: #fff;
}

.scs-image a:active {
	font-weight: 400;
}

.scs-image-default-style img,
.scs-img-rounded-corners img {
	border-radius: 8px;
}

.scs-img-rounded-top img {
	border-radius: 8px 8px 0 0;
}

.scs-img-rounded-bottom img {
	border-radius: 0 0 8px 8px;
}

.scs-img-rounded-right img {
	border-radius: 0 8px 8px 0;
}

.scs-img-rounded-left img {
	border-radius: 8px 0 0 8px;
}

.scs-img-square img {
	border-radius: 0;
}

.scs-gallery-style-15 .scs-component-content,
.scs-image-style-15 .scs-component-content {
	position: relative;
}

.scs-gallery-style-15 .scs-component-content:after,
.scs-gallery-style-15 .scs-component-content:before,
.scs-image-style-15 .scs-component-content:after,
.scs-image-style-15 .scs-component-content:before {
	z-index: -1;
	position: absolute;
	content: '';
	bottom: 15px;
	left: 10px;
	width: 50%;
	top: 80%;
	max-width: 300px;
	background: #777;
	box-shadow: 0 15px 10px #777;
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	transform: rotate(-3deg);
}

.scs-gallery-style-15 .scs-component-content:after,
.scs-image-style-15 .scs-component-content:after {
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
	right: 10px;
	left: auto;
}

.scs-image-style-15 .scs-image-image {
	box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.2) inset;
	border: 1px rgba(0, 0, 0, 0.1) solid;
}

.scs-gallery-style-16 .scs-component-content,
.scs-image-style-16 .scs-image-image {
	box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
	border: 10px #fff solid;
	box-sizing: border-box;
	border-radius: 3px;
}

.scs-image-style-16 .scs-image-caption {
	left: 10px;
	bottom: 10px;
	right: 10px;
}

.scs-app-style-2 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-2>.scs-component-content,
.scs-document-style-2 .scs-component-content,
.scs-gallery-style-2 .scs-component-content,
.scs-map-style-2 .scs-component-content,
.scs-socialbar-style-2 .scs-component-content,
.scs-video-style-2 .scs-component-content,
.scs-youtube-style-2 .scs-component-content iframe {
	border: 1px solid #999;
	box-sizing: border-box;
	padding: 5px;
}

.scs-app-style-8 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-8>.scs-component-content,
.scs-document-style-8 .scs-component-content,
.scs-gallery-style-8 .scs-component-content,
.scs-map-style-8 .scs-component-content,
.scs-socialbar-style-8 .scs-component-content,
.scs-video-style-8 .scs-component-content,
.scs-youtube-style-8 .scs-component-content iframe {
	border: 2px solid #333;
	box-sizing: border-box;
	padding: 5px;
}

.scs-app-style-9 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-9>.scs-component-content,
.scs-document-style-9 .scs-component-content,
.scs-gallery-style-9 .scs-component-content,
.scs-map-style-9 .scs-component-content,
.scs-socialbar-style-9 .scs-component-content,
.scs-video-style-9 .scs-component-content,
.scs-youtube-style-9 .scs-component-content iframe {
	border: 5px solid #333;
	box-sizing: border-box;
	padding: 5px;
}

.scs-app-style-10 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-10>.scs-component-content,
.scs-document-style-10 .scs-component-content,
.scs-gallery-style-10 .scs-component-content,
.scs-map-style-10 .scs-component-content,
.scs-socialbar-style-10 .scs-component-content,
.scs-video-style-10 .scs-component-content,
.scs-youtube-style-10 .scs-component-content iframe {
	box-shadow: -5px 5px 5px 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
}

.scs-app-style-11 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-11>.scs-component-content,
.scs-document-style-11 .scs-component-content,
.scs-gallery-style-11 .scs-component-content,
.scs-map-style-11 .scs-component-content,
.scs-socialbar-style-11 .scs-component-content,
.scs-video-style-11 .scs-component-content,
.scs-youtube-style-11 .scs-component-content iframe {
	box-shadow: 5px 5px 5px 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
}

.scs-app-style-12 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-12>.scs-component-content,
.scs-document-style-12 .scs-component-content,
.scs-gallery-style-12 .scs-component-content,
.scs-map-style-12 .scs-component-content,
.scs-socialbar-style-12 .scs-component-content,
.scs-video-style-12 .scs-component-content,
.scs-youtube-style-12 .scs-component-content iframe {
	box-shadow: -5px 5px 0 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
}

.scs-app-style-13 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-13>.scs-component-content,
.scs-document-style-13 .scs-component-content,
.scs-gallery-style-13 .scs-component-content,
.scs-map-style-13 .scs-component-content,
.scs-socialbar-style-13 .scs-component-content,
.scs-video-style-13 .scs-component-content,
.scs-youtube-style-13 .scs-component-content iframe {
	box-shadow: 5px 5px 0 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
}

.scs-app-style-14 .scs-component-content .scs-app-iframe-wrapper,
.scs-component-style-14>.scs-component-content,
.scs-document-style-14 .scs-component-content,
.scs-gallery-style-14 .scs-component-content,
.scs-map-style-14 .scs-component-content,
.scs-socialbar-style-14 .scs-component-content,
.scs-youtube-style-14 .scs-component-content iframe {
	box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.8);
	box-sizing: border-box;
}

.scs-sectionlayout-gap > .scs-component-content > .sl-two-columns,
.scs-sectionlayout-gap > .scs-component-content > .sl-three-columns {
	gap: 1rem;
}

.scs-sectionlayout-style-two-column-separator .sl-two-columns-left {
	border-right: 1px solid;
	padding-right: 1rem;
	margin-right: 1rem;
}

.scs-sectionlayout-style-three-column-left-separator .sl-three-columns-center {
	border-left: 1px solid;
	padding-left: 1rem;
	margin-left: 1rem;
}

.scs-sectionlayout-style-three-column-right-separator .sl-three-columns-center {
	border-right: 1px solid;
	padding-right: 1rem;
	margin-right: 1rem;
}

.scs-sectionlayout-style-three-column-center-separator .sl-three-columns-center {
	border-right: 1px solid;
	border-left: 1px solid;
	padding-right: 1rem;
	margin-right: 1rem;
	padding-left: 1rem;
	margin-left: 1rem;
}

.scs-sectionlayout-style-11 {
	box-shadow: 5px 5px 5px 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
	margin: 5px;
}

.scs-sectionlayout-style-12 {
	box-shadow: -5px 5px 0 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
	margin: 5px;
}

.scs-sectionlayout-style-13 {
	box-shadow: 5px 5px 0 0 rgba(51, 51, 51, 0.3);
	box-sizing: border-box;
	margin: 5px;
}

.scs-sectionlayout-style-14 {
	box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.8);
	box-sizing: border-box;
	margin: 5px;
}

.scs-divider-style-2 hr {
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.75), transparent);
	background-image: -moz-linear-gradient(left, transparent, rgba(0, 0, 0, 0.75), transparent);
	background-image: -ms-linear-gradient(left, transparent, rgba(0, 0, 0, 0.75), transparent);
	background-image: -o-linear-gradient(left, transparent, rgba(0, 0, 0, 0.75), transparent);
}

.scs-divider-style-3 hr {
	border-top: 1px dotted #333;
}

.scs-divider-style-4 hr {
	border-top: 3px dotted #333;
}

//Ugliness due to specificity battle
.scs-component.scs-button .scs-button-button {
	padding: 0;

	.scs-button-text {
		cursor: pointer;
		padding: 10px 15px;
		@include fontSettings('buttonS');
		white-space: wrap;
	}
}

.scs-button-primary,
.scs-button-default-style {
	.scs-component-content {
		background-color: $color-primary-medium-bg;
		border: 1px solid $color-primary-medium-bg;


		&:hover,
		&:focus-within {
			background-color: $color-primary;
		}
	}

	.scs-button-text {
		color: $color-neutral-100;
	}
}

.scs-button-secondary {
	.scs-component-content {
		background-color: $color-neutral-100;
		border: 1px solid $color-primary;

		&:hover,
		&:focus-within {
			background-color: $color-primary;

			.scs-button-text {
				color: $color-neutral-100;
			}
		}
	}

	.scs-button-text {
		color: $color-primary;
	}
}

.scs-button-tertiary {
	.scs-component-content {

		&:hover,
		&:focus-within {
			text-decoration: underline;
		}
	}

	.scs-button-text {
		color: $color-primary;
	}
}

.scs-button-tertiary-dark {
	.scs-component-content {
		background-color: $color-neutral-20;

		&:hover,
		&:focus-within {
			text-decoration: underline;
		}
	}

	.scs-button-text {
		@include fontSettings('buttonS');
		color: $color-neutral-100;
	}
}

.scs-cobrowse-style-1 .scs-button-button {
	text-decoration: none;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	text-align: center;
	font-size: 16px;
	color: #000;
	background: 0 0;
}

.scs-cobrowse-style-1 .scs-button-text {
	color: #000;
}

.scs-cobrowse-style-1 .scs-button-text:hover {
	color: #000;
	cursor: pointer;
	text-decoration: underline;
}

.scs-cobrowse-style-2 .scs-button-button {
	text-decoration: none;
	padding: 2px !important;
	background: 0 0;
}

.scs-cobrowse-style-2 .scs-button-text {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	color: #000;
}

.scs-cobrowse-style-2 .scs-button-text:hover {
	color: #000;
	cursor: pointer;
	text-decoration: underline;
}

.scs-cobrowse-style-2 .scs-button-text::before {
	content: url(cobrowseLaunch.png);
	cursor: pointer;
	padding-right: 4px;
	vertical-align: -30%;
}

.scs-title {

	&-headerXL *,
	&-default-style * {
		@include fontSettings('headingXL');
	}

	&-headerL * {
		@include fontSettings('headingL');
	}

	&-headerM * {
		@include fontSettings('headingM');
	}

	&-headerS * {
		@include fontSettings('headingS');
	}

	&-headerXS * {
		@include fontSettings('headingXS');
	}

	&-blockquote{
		font-family: $font-title-stack;
		font-size:2.25rem;
		font-weight: 700;
		line-height: 2.5rem;
	}

	&-headerTitle {
		color: $color-primary;
		border-bottom: 1px solid $color-primary-medium-bg;
		padding-bottom: 0.5rem;

		* {
			@include fontSettings('headingXL');
		}
	}

	&-sectionTitle {
		color: $color-primary;
		border-bottom: 1px solid $color-primary-medium-bg;
		padding-bottom: 0.5rem;

		* {
			@include fontSettings('headingL');
		}
	}

	//div needed for specificity fight
	div.scs-title-text {
		overflow: unset;
	}
}

//Map Fix
.scs-map img{
	max-width: unset;
}

//add hidden title styles
body {
	&.scs-edit-mode {
		.scs-title-hidden-h1 {
			display: block;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1;
			padding: 0.5rem;
			// padding-bottom: 1.5rem;
			// margin-block: 2rem;
			border-bottom: 1px solid var(--color-grey-medium);
			outline: 4px dashed gainsboro;
			border-radius: 1rem;
			//add "this is a hidden title for accessibility" text
			&::after {
				content: 'This is a hidden title for accessibility';
				display: block;
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 1;
				color: darkgray;
				padding-top: 0.5rem;
				margin-inline-start: .5rem;
			}
		}
	}

	&:not(.scs-edit-mode) {
		.scs-title-hidden-h1 {
			clip: rect(1px, 1px, 1px, 1px);
			clip-path: inset(50%);
			color: rgb(145, 145, 145);
			height: 1px;
			width: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
		}
	}
}

.scs-paragraph-default-style {
	// font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	// font-size: 16px;
	// font-weight: 400;
	// color: #333
	font-family: $font-stack;
	font-size: 1rem;
	line-height: 1.5;
}

.scs-paragraph-blockquote p {
	font-family: $font-stack;
	font-size: 2rem;
	font-weight: 400;
	line-height: 36px;
}


//Table Reset
.scs-paragraph:not(.scs-paragraph-edit) table {
	border-color: unset;
	border-style: unset;

	th {
		background-color: unset;
		border-color: unset;
		border-style: unset;
		font-weight: unset;
		text-align: unset;
	}

	td {
		background-color: unset;
		border-color: unset;
		border-style: unset;
		font-weight: unset;
		text-align: unset;
	}
}

/* Table Styling */

table,
.scs-paragraph:not(.scs-paragraph-edit) table {
	border: 1px solid $color-neutral-60;
	margin-bottom: 1rem;

	caption {
		@include fontSettings('headingS');
		@include colorScheme('primary');
		padding: 1rem 1.25rem;
		text-align: left;
	}

	th {
		@include fontSettings('paragraph');
		background-color: $color-neutral-80;
		padding: 0.5rem 1.25rem;
		border-bottom: 1px solid $color-neutral-60;
		font-weight: 700;
	}

	tr {
		&:hover {
			background-color: #9bcbeb40;
		}

		&:last-of-type {
			td {
				border-bottom: none;
			}
		}
	}

	td {
		@include fontSettings('paragraph');
		padding: 0.5rem 1.25rem;
		border-bottom: 1px solid $color-neutral-60;
	}
}

// .scs-paragraph-style-2 {
//     background-color: #ffccd8;
//     color: #333;
//     font-family: "Lucida Grande", Verdana, Helvetica, Arial, sans-serif;
//     border: 1px solid #ddd;
//     border-radius: 5px
// }

// .scs-paragraph-style-3 {
//     background-color: #def300;
//     color: #333;
//     font-size: larger
// }

// .scs-paragraph-style-4 {
//     font-size: 70%;
//     color: #08c;
//     border-top: 1px solid #999;
//     border-bottom: 1px solid #999;
//     padding-top: 1em;
//     padding-bottom: 1em
// }

// .scs-paragraph-style-5 {
//     font-size: 80%;
//     font-weight: 700;
//     color: #666;
//     border: 1px solid #999;
//     border-radius: 5px;
//     padding-top: 1em;
//     padding-bottom: 1em
// }

// .scs-paragraph-style-6 {
//     font-family: source-sans-pro, sans-serif;
//     color: #333;
//     background-color: #e5e5e5;
//     box-sizing: border-box;
//     padding-top: 2em;
//     padding-bottom: 2em
// }

@media screen and (max-width: 767px) {
	// .scs-paragraph:not(.scs-paragraph-edit) table {
	//     border: 0
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table caption {
	//     font-size: 1.3em
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table thead {
	//     border: none;
	//     clip: rect(0 0 0 0);
	//     height: 1px;
	//     margin: -1px;
	//     overflow: hidden;
	//     padding: 0;
	//     position: absolute;
	//     width: 1px
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table tr {
	//     border-bottom: 3px solid #ddd;
	//     display: block;
	//     margin-bottom: .625em
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table td {
	//     border-bottom: 1px solid #ddd;
	//     display: block;
	//     text-align: right
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table td::before {
	//     content: attr(data-label);
	//     float: left;
	//     font-weight: 700
	// }

	// .scs-paragraph:not(.scs-paragraph-edit) table td:last-child {
	//     border-bottom: 0
	// }
}

.scs-document-cap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	color: #fff;
}

.scs-document-title {
	position: relative;
	z-index: 2;
	padding: 0.5em;
	opacity: 0.54;
	background-color: #000;
}

.scs-document-desc {
	font-style: italic;
	font-size: 12px;
	color: #ccc;
	padding-top: 2px;
}

.scs-document-capb {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0%;
	z-index: 1;
	opacity: 0.5;
	background-color: #000;
}

.scs-socialbar-default-style {
	background-color: transparent;
}

.scs-contentlist {
	line-height: 1;

	p {
		margin-bottom: 0.3rem;
	}

	>.scs-component-content {
		width: 100%;
	}

	&.scs-contentlist-default-style {
		padding-bottom: 0;

		ol.scs-pagination {
			li {
				&.scs-clickable {
					background-color: unset;

					a {
						font-weight: 700;
						font-size: 1.1875rem;
						line-height: 1.4375;
						padding: 0.5rem 1rem;
						margin-top: 1rem;
						color: white;
						background-color: $color-primary-medium;

						&:hover {
							background-color: $color-primary;
						}
					}

					&:hover a {
						color: #fff;
						background-color: #85bbe7;
					}

					&.scs-current a {
						color: #fff;
						background-color: #027bc7;
					}

					&.scs-disabled a {
						color: #9e9e9e;
						background-color: #ececec;
					}
					&.scs-current, &.scs-disabled {
						// color: black;
						background-color: transparent;
					}
				}
				&.ellipses {
					translate: 0 2rem;
				}
			}
		}
	}

	&.scs-contentlist-load-button {
		@extend .scs-contentlist-default-style;
		padding-bottom: 3rem;
	}
}

.news-article-default-style .scs-image {
	border: 1px solid #0572ce;
	box-sizing: border-box;
	padding: 5px;
	font-size: 16px;
	color: #333;
}

.news-article-style-1 .scs-image {
	border: 1px solid red;
	box-sizing: border-box;
	padding: 5px;
	font-size: 18px;
	color: #333;
}

.scs-title-style-base,
.scs-title-style-heading-l,
.scs-title-style-heading-m,
.scs-title-style-heading-s,
.scs-title-style-heading-xl,
.scs-title-style-sub-title,
.scs-title-style-title {
	padding: 0;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0.5em;
	margin-left: 0;
}

.scs-title-style-title {
	font-weight: 700;
	font-size: 1em;
	line-height: 1.4em;
	text-align: center;
	text-transform: uppercase;
}

.scs-title-style-sub-title {
	font-style: italic;
	font-weight: 400;
	font-size: 0.8333em;
	line-height: 1.4em;
	text-align: center;
}

.scs-title-style-heading-xl {
	font-weight: 700;
	font-size: 1em;
	line-height: 1.4em;
}

.scs-title {
	&.scs-text-heading-style-1 {
		.scs-text {
			color: $color-primary;
			border-bottom: 1px solid $color-primary-medium-bg;
			@include fontSettings('headingXL');
		}
	}

	&.scs-text-heading-style-2 {
		.scs-text {
			@include fontSettings('headingL');
		}
	}

	&.scs-text-heading-style-3 {
		.scs-text {
			@include fontSettings('headingM');
		}
	}

	&.scs-text-heading-style-4 {
		.scs-text {
			@include fontSettings('headingS');
		}
	}

	.scs-text {
		padding-bottom: 0.5rem;
	}
}

.scs-title-style-heading-l {
	font-style: italic;
	font-weight: 400;
	font-size: 0.8333em;
	line-height: 1.4em;
}

.scs-title-style-heading-m {
	font-weight: 700;
	font-size: 0.6666em;
	line-height: 1.4em;
}

.scs-title-style-heading-s {
	font-style: italic;
	font-weight: 400;
	font-size: 0.5em;
	line-height: 1.4em;
}

.scs-paragraph-style-base,
.scs-paragraph-style-body-l,
.scs-paragraph-style-body-m,
.scs-paragraph-style-body-s,
.scs-paragraph-style-cite,
.scs-paragraph-style-code,
.scs-paragraph-style-heading-l,
.scs-paragraph-style-heading-m,
.scs-paragraph-style-heading-s,
.scs-paragraph-style-heading-xl,
.scs-paragraph-style-heading-xs,
.scs-paragraph-style-quote {
	padding: 0;
	margin-top: 0.3em;
	margin-right: 0;
	margin-bottom: 0.3em;
	margin-left: 0;
}

.scs-paragraph-style-heading-xl {
	font-weight: 700;
	font-size: 2em;
	line-height: 1.4em;
}

.scs-paragraph-style-heading-l {
	font-style: italic;
	font-weight: 400;
	font-size: 1.5em;
	line-height: 1.4em;
}

.scs-paragraph-style-heading-m {
	font-weight: 700;
	font-size: 1.125em;
	line-height: 1.4em;
}

.scs-paragraph-style-heading-s {
	font-style: italic;
	font-weight: 400;
	font-size: 1em;
	line-height: 1.4em;
}

.scs-paragraph-style-heading-xs {
	font-weight: 700;
	font-size: 0.8125em;
	line-height: 1.4em;
}

.scs-paragraph-style-body-l {
	font-size: 1.125em;
	line-height: 1.4em;
}

.scs-paragraph-style-body-m {
	font-size: 1em;
	line-height: 1.4em;
}

.scs-paragraph-style-body-s {
	font-size: 0.8125em;
	line-height: 1.4em;
}

.scs-paragraph-style-code {
	padding-left: 1.5em;
	font-family: monospace, serif;
	font-size: 0.8125em;
	line-height: 1.3em;
	border: 1px solid silver;
}

.scs-paragraph-style-quote {
	padding-left: 1.5em;
	font-style: italic;
	font-size: 0.8125em;
	line-height: 1.3em;
	border-left: 1px solid silver;
}

.scs-paragraph-style-cite {
	font-size: 0.8125em;
}

.scs-facebook-icon {
	background-image: url(facebook.png);
}

.scs-twitter-icon {
	background-image: url(twitter.png);
}

.scs-linkedin-icon {
	background-image: url(linkedin.png);
}

.scs-googleplus-icon {
	background-image: url(googleplus.png);
}

.scs-youtube-icon {
	background-image: url(youtube.png);
}

.scs-flickr-icon {
	background-image: url(flickr.png);
}

.scs-contentsearch-style-1 .scs-search-input {
	padding: 0 5px 0 5px;
	width: calc((100% - 12px));
	height: 27px;
	font-size: 12px;
	color: #333;
	border: 1px solid #c4ced7;
}

.scs-contentsearch-style-1 .scs-search-input[data-show-icon='true'] {
	width: calc((100% - 37px));
	padding-right: 30px;
}

.scs-contentsearch-style-1 .scs-search-button {
	top: 1px;
	right: 0;
	width: 29px;
	height: 25px;
	margin: 1px 1px 1px 0;
	border-left: 1px solid #dfe3e7;
	background-size: 15px auto;
	background-position: 6px;
	background-color: #f5f5f5;
}

.scs-contentsearch-style-1 .scs-search-button:hover {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjE1cHgiIHZpZXdCb3g9IjAgMCAxNyAxNyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTcgMTciIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiM4NUJCRTciIGQ9Ik0xNS42MjUsMTMuNzE3bC0zLjQzOC0zLjI1QzEyLjgxMiw5LjQ2NSwxMy4yNSw4LjM0LDEzLjI1LDcuMDljMC0zLjM3NS0yLjc1LTYuMTI2LTYuMTI1LTYuMTI2UzAuOTk5LDMuNzE1LDAuOTk5LDcuMDljMCwzLjM3NiwyLjc1LDYuMTI2LDYuMTI1LDYuMTI2YzEuMjUsMCwyLjQzNy0wLjM3NSwzLjQzOC0xLjA2NGwzLjE4OCwzLjUwMmMwLjQ5OSwwLjQ5OCwxLjMxMiwwLjQ5OCwxLjg3NSwwLjA2MUMxNi4xMjUsMTUuMDI3LDE2LjEyNSwxNC4yMTUsMTUuNjI1LDEzLjcxNyBNNy4xMjUsMTEuMjc3Yy0yLjMxMiwwLTQuMjUtMS44NzUtNC4yNS00LjI1YzAtMi4zNzUsMS44NzUtNC4yNSw0LjI1LTQuMjVzNC4yNSwxLjg3NSw0LjI1LDQuMjVjMCwwLjc1LTAuMTg4LDEuNDM4LTAuNTYzLDIuMDYyQzEwLjA2MiwxMC40MDIsOC42ODcsMTEuMjc3LDcuMTI1LDExLjI3NyIvPjwvc3ZnPg==);
}

.scs-contentsearch-style-2 .scs-search-input {
	width: 100%;
	height: 34px;
	font-size: 14px;
	color: #555;
	border: 1px solid #d3d3d3;
	border-radius: 4px;
	border-color: #ccc;
	box-sizing: border-box;
	padding: 6px 12px;
}

.scs-contentsearch-style-2 .scs-search-input[data-show-icon='true'] {
	width: 100%;
	padding-right: 40px;
}

.scs-contentsearch-style-2 .scs-search-button {
	top: 1px;
	right: 0;
	width: 38px;
	height: 30px;
	margin: 1px 1px 1px 0;
	box-sizing: border-box;
	border-left: 1px solid #ccc;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-size: 14px auto;
	background-position: 10px;
}

.scs-contentsearch-style-2 .scs-search-button:hover {
	background-color: #e6e6e6;
}

.rss-caption-heading-default-style .rss-caption-heading {
	background: #be2c12;
	color: #fff;
	font-family: Verdana;
	font-size: 15px;
}

.rss-caption-heading-custom-style-1 .rss-caption-heading {
	background: #0f9633;
	color: #fff;
	font-family: Verdana;
	font-size: 15px;
}

.rss-caption-heading-custom-style-2 .rss-caption-heading {
	background: #746763;
	color: #fff;
	font-family: Verdana;
	font-size: 15px;
}

.scs-cookie-consent {
	border: 1px solid #d9d9d9;
	border-bottom: none;
	border-radius: 8px 8px 0 0;
	background-color: #f0f0f0;
	align-items: center;
	font-family: Helvetica Neue, Helvetica, Arial, Utkal, sans-serif;
	font-size: 16px;
	font-weight: 300;
	color: #262626;
	line-height: 1.4;
	box-sizing: border-box;
	padding: 20px 70px 20px 15px;
	margin-left: 10px;
	display: inline-block;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99999;
}

.scs-cookie-consent a {
	color: #006598;
	font-weight: 700;
	text-decoration: none;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
}

.scs-cookie-consent .scs-cookie-consent-close {
	display: block;
	float: right;
	margin: 0;
	background-color: #000;
	border-radius: 100px;
	position: absolute;
	right: 6px;
	top: 6px;
	cursor: pointer;
	width: 36px;
	height: 36px;
	color: #fff;
	text-align: center;
	background-image: url(x-close.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 12px 12px;
}

.scs-cookie-consent a:hover {
	color: red;
}

.pswp .scs-lightbox-caption {
	text-align: center;
	max-width: none;
	font-size: 14px;
}

.pswp .scs-lightbox-caption small {
	font-size: 12px;
}

.test {
	font: 14px;
}

.scs-componentgroup .scs-componentgroup-green {
	border-radius: unset;
	display: flex;
	align-items: center;
	justify-content: center;

	&>.scs-component-content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		max-width: 380px;
		background-color: $color-secondary;
		padding: 1.5rem 0;

		.scs-row {
			width: unset;
		}
	}

	@include mq('mobile-large', max) {
		&>.scs-component-content {
			max-width: unset;
		}
	}
}

.scs-componentgroup .scs-componentgroup-callout {
	border-radius: 0.5rem;
	padding: 1.5rem;

	&.scs-componentgroup-primary {
		background-color: $color-primary;

		div {
			color: $color-neutral-100;
		}

		a {
			color: $color-neutral-100;
			border-color: $color-neutral-100;
		}
	}

	&.scs-componentgroup-secondary {
		background-color: $color-primary-light-bg;

		a {
			& {
				color: $color-primary;
			}
			&:hover {
				color: $color-primary-hover;
			}
		}

		div {
			color: $color-neutral-0;
			border-color: $color-primary;
		}
	}

	&.scs-componentgroup-white {
		background-color: $color-neutral-100;

		div {
			color: $color-primary;
		}
	}

	&.scs-componentgroup-light-gray {
		background-color: $color-neutral-80;
		border: 1px solid $color-neutral-60;

		div {
			color: $color-primary;
		}
	}

	&.scs-componentgroup-block {
		background-color: $color-primary-light;
		position: relative;
		z-index: 10;
		padding: 4rem 5rem;
		min-height: 18.5rem;
		border-radius: unset;

		@include mq('mobile-large', max){
			padding:4rem 3rem;
		}

		div {
			&.scs-paragraph-text {
				@include fontSettings('headingM');
				overflow: hidden;
			}

			&.scs-title-text {
				div {
					@include fontSettings('headingL');
					overflow: hidden;
				}
			}
		}
	}

	&.scs-componentgroup-blockquote {
		@extend .scs-componentgroup-block;
		overflow: hidden;

		&::before {
			@include material('format_quote');
			color: $color-primary-medium-bg;
			opacity: 0.2;
			font-size: 18.5rem;
			transform: rotate(180deg);
			position: absolute;
			top: 4.5rem;
			left: -2.25rem;
			background-repeat: no-repeat;
			z-index: -5;
		}

		&::after {
			@include material('format_quote');
			color: $color-primary-medium-bg;
			opacity: 0.2;
			font-size: 18.5rem;
			position: absolute;
			bottom: 4.5rem;
			right: -2.25rem;
			z-index: -5;
		}
	}

	div {
		&.scs-paragraph-text {
			@include fontSettings('paragraph');
		}

		&.scs-title-text {
			div {
				@include fontSettings('headingL');
				overflow: hidden;
			}
		}

		&.scs-title-text {
			div {
				@include fontSettings('headingL');
				overflow: hidden;
			}
		}
	}
}

/* clean-css ignore:start */

//Using container queries for this. Awkward selectors are due to specificity issues.
.scs-componentgroup-callout:not(.scs-componentgroup-blockquote) {
	container-type: inline-size;
	container-name: callout;

	@container callout (max-width:600px) {
		.scs-component-container:first-of-type .scs-component div {
			@include fontSettings('headingM');
			overflow-y: hidden;
		}
	}
}

/* clean-css ignore:end */

// Fix for bullet points not showing up
.scs-paragraph,
.mnh-composition {
	ul,
	ol {
		margin-inline-start: 2rem;
		&:not(:only-child) {
			margin-block: 1rem;
		}
	}
}
